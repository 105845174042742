@tailwind base;
@tailwind components;
@tailwind utilities;

.blur {
    --tw-blur: blur(1px);
    backdrop-filter: blur(1px);
}

.custom-option {
    transition: background 1ms;
}
.custom-option:hover {
    transition-delay: 1ms;
    background: #deebff;
}
.custom-option.custom-select__option--is-focused {
    background: #2684ff;
    color: #ffffff;
}
.custom-option.custom-select__option--is-selected {
    background: #2684FF;
}

.react-date-picker__inputGroup__input:invalid {
    background: #e6e6e6 !important;
}
.react-date-picker__wrapper {
    border: none !important;
}
.react-date-picker__button .react-date-picker__button__icon {
    stroke: black !important;
}
.react-date-picker__button:hover .react-date-picker__button__icon {
    stroke: #0078d7 !important;
}

.react-date-picker--disabled {
    background: #ffffff !important;
}

@layer utilities {
    .custom-scrollbar::-webkit-scrollbar {
        width: 7px;
        height: 20px;
    }

    .custom-scrollbar::-webkit-scrollbar-track {
        border-radius: 100vh;
    }

    .custom-scrollbar::-webkit-scrollbar-thumb {
        background: rgb(243 244 246);
        border-radius: 100vh;
        border: 1px solid rgb(243 244 246);
    }
    .custom-scrollbar::-webkit-scrollbar-thumb:hover {
        background: rgb(229 231 235);
        border-radius: 100vh;
        border: 1px solid rgb(229 231 235);
    }

    @media (prefers-color-scheme: dark) {
        .custom-scrollbar::-webkit-scrollbar-thumb {
            background: rgb(75 85 99);
            border-radius: 100vh;
            border: 1px solid rgb(75 85 99);

        }
        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
            background: rgb(55 65 81);
            border-radius: 100vh;
            border: 1px solid rgb(55 65 81);

        }
    }
}

